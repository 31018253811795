<template>
  <div class="container pt-5">
    <h1 class="pt-5">This is an list page</h1>
    <div class="container pt-5">
    <div class="row">
      <ul v-for="item in 10" class="list-group  justify-content-between">
    <li class="list-group-item mb-3">
      <div class="col">
        <img src="../assets/logo.png" style="width:20%;height:20%;" class="">
        <p>This is the content of the about page{{item}}.</p>
      </div>
      <div class="col">
        <button class="btn btn-success justify-content-end">download</button>
      </div>
      </li>

      </ul>
    </div>
  </div>
  </div>

</template>
