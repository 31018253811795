<template>
    <div class="bg">
        <div class="bg2">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
                        aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand col-2" href="/">&nbsp;&nbsp;&nbsp;&nbsp;yx8短视频解析 &nbsp; </a>

                <div class="collapse navbar-collapse " id="navbarToggler01">
                    <ul class="navbar-nav mr-auto mt-2   ">
                        <li class="nav-link">
                            <router-link to="/">短视频解析</router-link>
                        </li>
                        <li class="nav-link ">
                            <router-link to="/list">热门</router-link>
                        </li>
                        <!--    <li class="nav-link "><router-link to="/about">关于</router-link> </li>-->

                    </ul>

                </div>
                <div class="collapse navbar-collapse justify-content-end align-items-end" id="navbarToggler02">
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ">
                        <!--              <li class="nav-link "><router-link to="/s">关于</router-link> </li>-->
                        <!--    <li class="nav-link "><router-link to="/about">关于</router-link> </li>-->
                        <!--    <li class="nav-link "><router-link to="/login">登录</router-link> </li>-->
                        <li class="nav-link ">
                            <router-link to="/about">关于</router-link>&nbsp; &nbsp;
                        </li>
                    </ul>
                </div>
            </nav>
            <footer>
                <i>视频归相关网站及作者所有，本站不存储任何视频及图片。 </i><br>
                <i> @ copyright &nbsp; <a href="/">我的主页</a> &nbsp; 2024</i>
            </footer>

        </div>
    </div>
    <router-view/>
</template>
<script>
export default {
  name: 'App',
  components: {
  },


}

</script>
<style lang="scss">
body{ margin:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg{
   position: fixed;
   width:100%;
   height:100%;
    //background: linear-gradient( #3498db, #9b59b6);
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #f1f1f1;
    text-decoration: none;

    &.router-link-exact-active {
      color: #f1f1f1;
    }
  }
}
footer {
  padding: 20px;
  position: absolute;
  bottom: 0; /* 4. 设置页脚position为absolute之后，再将其bottom值设为0，即可使其处于页面的底部 */
  width: 100%;
  i {

  color: #000;
  }
}


</style>
