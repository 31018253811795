<template>
    <div class="container pt-5">
        <img alt="Vue logo" src="../assets/logo.png" class="pt-5">
        <h1>本站已支持解析哔哩哔哩、抖音、快手、小红书、皮皮虾、西瓜视频、Tik Tok、微视、微博、京东、YouTube、好看视频等平台的视频,
            输入视频链接自动解析。</h1>
        <div class="container pt-5">
            <div class="input-group col-8">
                <input type="text" class="form-control" placeholder="https://v.douyin.com/iAmVyNN5/">
                <div class="input-group-append">
                    <button class="btn btn-success col" id="basic-addon2" @click="getdata()">解 析</button>
                </div>
            </div>
        </div>
        <div class="container pt-5 justify-content-between">
    <span>
        <h3>提示：</h3>
           <p> 粘贴地址时无需删除文案但如果视频链接正确但解析失败请删掉文案后重试</p>

            <p>如果发现无法解析，可留言反馈。站长会及时修复问题</p>

       <h3> 特别注意：</h3>

            <p>如果要解析 https://www.bilibili.com/ 详细视频 请切换工具 B站视频解析 工具</p>
    </span>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <ul v-for="item in videoList">
<!--                         <h3>视频</h3>-->
                        <li >{{ item }}</li>
                        <button class="btn btn-primary">下 载</button>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
    name: 'App',
    components: {
        //HelloWorld
    },
    data() {
        return {
            videoUrl: '',
            videoList: []
        }
    },
    methods: {
        async getdata() {
            console.log('wait...')
            let url = 'https://edith.xiaohongshu.com/api/sns/web/v1/user_posted?num=30&cursor=67109fbb000000001b02c151&user_id=66bc038f000000001d02220c&image_formats=jpg,webp,avif&xsec_token=&xsec_source=pc_note'
            try {
                let res = await axios.get(url)
                let data = res.data.notes
                this.videoList = [data.title, data.author.name, data.desc, data.pic, data.pubdate]
            } catch (error) {
                console.error(error)
                this.videoList = ['解析失败', 'a', 'b', 'c', 'd']
            }
        }
    }

}


</script>
